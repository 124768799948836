import {StrictMode} from 'react'
import {createRoot} from 'react-dom/client'
import App from './app.tsx'
import {Provider} from "react-redux";
import {persistor, store} from "../store/store.ts";
import {CssBaseline, ThemeProvider} from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '../assets/fonts/victor-mono/stylesheet.css';
import {PersistGate} from "redux-persist/integration/react";
import {theme} from "./theme.ts";

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </StrictMode>,
)
