import {useParams} from "react-router-dom";
import {useAppSelector} from "../../store/hooks.ts";
import {Box, List, ListItem, ListItemText, Typography} from "@mui/material";
import {GroupAttitudeView} from "../components/attitude.tsx";
import {getCurrentGroupAttitude} from "../../store/model/world.ts";
import NPCListItem from "../components/npc-listitem.tsx";
import EntityLayout from "./_layout.tsx";
import GroupListItem from "../components/group-listitem.tsx";
import {ModifyGroupAttitudeButton} from "../components/modify-group-attitude-form.tsx";

export default function GroupPage() {
  const { groupName } = useParams()
  const { group, groupAttitude, ally, enemy, } = useAppSelector((state) => {
    const group = state.world.data.groups[groupName!.toLowerCase()]!

    const allyName = state.world.data.allies[group.name.toLowerCase()];
    const enemyName = state.world.data.enemies[group.name.toLowerCase()];
    return ({
      group,
      groupAttitude: getCurrentGroupAttitude(state.world, groupName!.toLowerCase()),
      ally: allyName ? state.world.data.groups[allyName.toLowerCase()] : undefined,
      enemy: enemyName ? state.world.data.groups[enemyName.toLowerCase()] : undefined,
    });
  })

  const npcs = useAppSelector((state) => (state.world.derived.groupNPCs[groupName!.toLowerCase()] ?? []).map(npcName => state.world.data.npcs[npcName]!))

  return (
    <EntityLayout
      entity={group}
      metadata={<>
        <Box sx={{ mb: 4 }}>
          <GroupAttitudeView variant={"h6"} attitude={groupAttitude}/>
          <ModifyGroupAttitudeButton groupName={group.name.toLowerCase()} />
        </Box>
        {ally && <>
            <Typography variant="h6">Ally</Typography>
            <GroupListItem group={ally} hideAlly />
          </>
        }
        {enemy && <>
          <Typography variant="h6">Enemy</Typography>
          <GroupListItem group={enemy} hideEnemy />
        </>
        }
        <Typography variant="h6" sx={{ mt: 4 }}>NPCs</Typography>
        <List>
          {npcs.length > 0 ? npcs.map(npc => (
            <NPCListItem key={npc.name} npc={npc} />
          )) : <ListItem><ListItemText primary={"None"} /></ListItem>}
        </List>
      </>}
    />
  )
}