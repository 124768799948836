import {SxProps} from "@mui/material";
import {Theme} from "@mui/material/styles";

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string, colorKey: string = name, sx: SxProps<Theme> = []) {
  const names = name.split(" ");

  let label = names[0]![0]!;
  if (names.length > 1) {
    label += names[names.length - 1]![0]!;
  }

  const sxProp: SxProps<Theme> = [
    ...(Array.isArray(sx) ? sx : [sx]),
    theme => ({
      bgcolor: stringToColor(colorKey),
      color: theme.palette.getContrastText(stringToColor(colorKey))
    })
  ]

  return {
    sx: sxProp,
    children: label,
  };
}
