import {World} from "./world.ts";
import XLSX from "xlsx";
import {utils} from "./xlsx.ts";
import {xlsxError} from "./error.ts";

export async function loadAgendaItemsFromWorkbook(world: World, workbook: XLSX.WorkBook) {
  const worksheet = workbook.Sheets["Agenda"];
  if (!worksheet) {
    throw xlsxError('Agenda', 'sheet not found');
  }

  const raw: Array<Array<unknown>> = utils.sheet_to_json(worksheet, { raw: true, header: 1 })
  // console.log("raw", raw)

  for (let i = 3; raw[i]?.length ?? 0 > 0; i++) {
    // console.log(raw[i])
    const row = raw[i]!
    const npcName = (row[1] as string).toLowerCase();
    // console.log("npcName")
    world.data.agendaItems.byNpc[npcName] = new Array(world.data.meta.maxTurns)

    for (let j = 2; j < row.length; j++) {
      const turnIndex = j - 2

      if (row[j] !== undefined) {
        // console.log(j, row[j])
        const location = (row[j] as string).toLowerCase();

        world.data.agendaItems.byNpc[npcName][turnIndex] = location;

        // derived
        world.derived.agendaItems.byPlace[location] ??= new Array(world.data.meta.maxTurns);
        world.derived.agendaItems.byPlace[location][turnIndex] ??= []
        world.derived.agendaItems.byPlace[location][turnIndex]!.push(npcName)
      }
    }
  }
}