import {z} from "zod";
import {World} from "./world.ts";
import XLSX from "xlsx";
import {createValidator} from "./xlsx.ts";
import {xlsxError} from "./error.ts";
import {assert} from "../../util/assert.ts";

const ThingSchema = z.object({
  name: z.string(),
  notes: z.string().optional(),
  visibilityMod: z.number().optional(),
  location: z.string().optional(),
});

// extract the inferred type
export type ThingData = z.infer<typeof ThingSchema>;
export type Thing = ThingData & {
  _type: 'thing'
}

export async function loadThingsFromWorkbook(world: World, workbook: XLSX.WorkBook) {
  const validator = createValidator(workbook, {
    sheetName: 'Things'
  })

  const result = validator.validate(ThingSchema);

  if (result.invalid.length > 0) {
    throw xlsxError('Things', result.invalid)
  }

  return loadThings(world, result.valid.map(r => r.data))
}

export function loadThings(world: World, datas: ThingData[]) {
  for (const [row, data] of datas.entries()) {
    const name = data.name.toLowerCase();
    const thing = {
      _type: 'thing',
      ...data,
      name,
      location: data.location?.toLowerCase(),
    } as const
    world.data.things[name] = thing

    if (thing.location) {
      const location = world.data.places[thing.location]
      assert(location !== undefined, `thing error (line ${row}): someone done gone stole yo dime bag: place ${thing.location} does not exist`)
    }
  }
}