import {z} from "zod";
import {World} from "./world.ts";
import XLSX from "xlsx";
import {createValidator} from "./xlsx.ts";
import {xlsxError} from "./error.ts";

const GroupAttitudeSchema = z.object({
  attitude1: z.number(),
  attitude2: z.number(),
  attitude3: z.number(),
  attitude4: z.number(),
  attitude5: z.number(),
})

const GroupSchema = z.intersection(z.object({
  id: z.string().or(z.number()).optional(),
  name: z.string(),
  notes: z.string().optional(),
  attitude1: z.number(),
  attitude2: z.number(),
  attitude3: z.number(),
  attitude4: z.number(),
  attitude5: z.number(),
}), GroupAttitudeSchema);

export type GroupData = z.infer<typeof GroupSchema>;
export type Group = GroupData & {
  _type: "group"
}
export type GroupAttitude = z.infer<typeof GroupAttitudeSchema>;

export async function loadGroupsFromWorkbook(world: World, workbook: XLSX.WorkBook) {
  const validator = createValidator(workbook, {
    sheetName: 'Groups'
  })

  const result = validator.validate(GroupSchema);

  if (result.invalid.length > 0) {
    throw xlsxError('Groups', result.invalid)
  }

  return loadGroups(world, result.valid.map(r => r.data))
}

export function loadGroups(world: World, datas: GroupData[]) {
  for (const data of datas) {
    const name = data.name.toLowerCase();
    world.data.groups[name] = {
      _type: 'group',
      ...data,
      name,
    }

    // derived
    world.state.groupAttitudes[name] ??= new Array(world.data.meta.maxTurns)
  }
}

export function groupAttitudeWithMod(attitude: GroupAttitude, mod: number): GroupAttitude {
  return {
    attitude1: attitude.attitude1 + mod,
    attitude2: attitude.attitude2 + mod,
    attitude3: attitude.attitude3 + mod,
    attitude4: attitude.attitude4 + mod,
    attitude5: attitude.attitude5 + mod,
  }
}

// const GroupSchema = z.object({
//   name: z.string(),
//   notes: z.string().optional(),
//   attitude0: z.number(),
//   attitude1: z.number(),
//   attitude2: z.number(),
//   attitude3: z.number(),
//   attitude4: z.number(),
// });
//
// const AllySchema = z.object({
//   group: z.string(),
//   ally: z.string()
// });
//
// const EnemySchema = z.object({
//   group: z.string(),
//   enemy: z.string()
// });
//
// // extract the inferred type
// export type GroupData = z.infer<typeof GroupSchema>;
// export type AllyData = z.infer<typeof AllySchema>;
// export type EnemyData = z.infer<typeof EnemySchema>;
//
// export async function loadGroupsFromFile(files: {
//   groups: File,
//   allies: File
//   enemies: File
// }): Promise<{
//   groups: GroupData[],
//   allies: AllyData[],
//   enemies: EnemyData[]
// }> {
//   const groupsResult = await parseCSV(files.groups, GroupSchema);
//   if (!groupsResult.success) {
//     throw csvError(files.groups.name, groupsResult.errors)
//   }
//
//   const alliesResult = await parseCSV(files.allies, AllySchema);
//   if (!alliesResult.success) {
//     throw csvError(files.allies.name, alliesResult.errors)
//   }
//
//   const enemiesResult = await parseCSV(files.enemies, EnemySchema);
//   if (!enemiesResult.success) {
//     throw csvError(files.enemies.name, enemiesResult.errors)
//   }
//
//   return {
//     groups: groupsResult.validRows,
//     allies: alliesResult.validRows,
//     enemies: enemiesResult.validRows,
//   }
// }
//
// export function loadGroups(world: World, datas: {
//   groups: GroupData[],
//   allies: AllyData[],
//   enemies: EnemyData[]
// }) {
//   const groupKeys = new Map<string, GroupKey>
//
//   for (const groupData of datas.groups) {
//     const key = uuidv4() as GroupKey
//     groupKeys.set(groupData.name, key)
//   }
//
//   for (const groupData of datas.groups) {
//     const key = groupKeys.get(groupData.name)!
//
//     const allyName = datas.allies.find(entry => entry.group.toLowerCase() === groupData.name.toLowerCase())?.ally
//     const allyKey = allyName ? groupKeys.get(allyName) : undefined
//
//     const enemyName = datas.enemies.find(entry => entry.group.toLowerCase() === groupData.name.toLowerCase())?.enemy
//     const enemyKey = enemyName ? groupKeys.get(enemyName) : undefined
//
//     world.groups[key] = {
//       _entity: true,
//       key,
//       name: groupData.name,
//       notes: groupData.notes,
//       ally: allyKey ?? null,
//       enemy: enemyKey ?? null,
//       attitude: {
//         attitudeValues: [
//           groupData.attitude0,
//           groupData.attitude1,
//           groupData.attitude2,
//           groupData.attitude3,
//           groupData.attitude4,
//         ]
//       }
//     }
//   }
// }