import {z} from "zod";
import {World} from "./world.ts";
import {Group} from "./group.ts";
import XLSX from "xlsx";
import {createValidator} from "./xlsx.ts";
import {xlsxError} from "./error.ts";
import {assert} from "../../util/assert.ts";

const AllySchema = z.object({
  group: z.string(),
  ally: z.string()
});

export type AllyData = z.infer<typeof AllySchema>;

export function getAllyOfGroup(world: World, groupName: string): Group | undefined {
  const allyEntry = world.data.allies[groupName]
  return allyEntry ? world.data.groups[allyEntry] : undefined
}

export async function loadAlliesFromWorkbook(world: World, workbook: XLSX.WorkBook) {
  const validator = createValidator(workbook, {
    sheetName: 'Allies'
  })

  const result = validator.validate(AllySchema);

  if (result.invalid.length > 0) {
    throw xlsxError('Allies', result.invalid)
  }

  return loadAllies(world, result.valid.map(r => r.data))
}

export function loadAllies(world: World, datas: AllyData[]) {
  for (const [row, data] of datas.entries()) {
    const name = data.group.toLowerCase();
    const group = world.data.groups[name]
    assert(group !== undefined, `ally error (line ${row}): someone done gone stole yo dime bag: group ${name} does not exist`)

    const allyName = data.ally.toLowerCase()
    const ally = world.data.groups[allyName]
    assert(ally !== undefined, `ally error (line ${row}): someone done gone stole yo dime bag: group ${allyName} does not exist`)
    
    world.data.allies[name] = data.ally.toLowerCase()
    // reversed lookup too
    world.data.allies[data.ally.toLowerCase()] = name
  }
}