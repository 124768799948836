import {z} from "zod";
import {World} from "./world.ts";
import {assert} from "../../util/assert.ts";
import {Group, GroupAttitude, groupAttitudeWithMod} from "./group.ts";
import XLSX from "xlsx";
import {createValidator} from "./xlsx.ts";
import {xlsxError} from "./error.ts";

const NPCStatsSchema = z.object({
  stat1: z.number(),
  stat2: z.number(),
  stat3: z.number(),
  stat4: z.number(),
  stat5: z.number(),
})

const NPCSchema = z.intersection(z.object({
  name: z.string(),
  notes: z.string().optional(),
  group: z.string(),
  attitudeMod: z.number(),
  location: z.string()
}), NPCStatsSchema);

// extract the inferred type
export type NPCData = z.infer<typeof NPCSchema>;
export type NPC = NPCData & {
  _type: 'npc'
}
export type NPCStats = z.infer<typeof NPCStatsSchema>;

export function getNPCAttitude(npc: NPC, npcGroup: Group): GroupAttitude {
  assert(npcGroup.name.toLowerCase() === npc.group.toLowerCase(), "NPC does not belong to passed Group")
  return groupAttitudeWithMod(npcGroup, npc.attitudeMod)
}

export async function loadNPCsFromWorkbook(world: World, workbook: XLSX.WorkBook) {
  const validator = createValidator(workbook, {
    sheetName: 'NPCs'
  })

  const result = validator.validate(NPCSchema);

  if (result.invalid.length > 0) {
    throw xlsxError('NPCs', result.invalid)
  }

  return loadNPCs(world, result.valid.map(r => r.data))
}

export function loadNPCs(world: World, datas: NPCData[]) {
  for (const [row, data] of datas.entries()) {
    const name = data.name.toLowerCase();
    const groupName = data.group.toLowerCase()
    const npc = {
      _type: 'npc',
      ...data,
      name,
      group: groupName,
      location: data.location.toLowerCase(),
    } as const
    world.data.npcs[name] = npc

    const group = world.data.groups[npc.group]
    assert(group !== undefined, `npc error (line ${row}): someone done gone stole yo dime bag: group ${npc.group} does not exist`)

    const location = world.data.places[npc.location]
    assert(location !== undefined, `npc error (line ${row}): someone done gone stole yo dime bag: place ${npc.location} does not exist`)

    world.derived.groupNPCs[groupName] = [...new Set([...world.derived.groupNPCs[groupName] ?? [], name])]
  }
}

export function npcAggregateAttitude(npc: NPC, groupAttitude: GroupAttitude): GroupAttitude {
  return groupAttitudeWithMod(groupAttitude, npc.attitudeMod)
}