import {Avatar, Box, Checkbox, Divider, FormControlLabel, Typography} from "@mui/material";
import React from "react";
import {capitalizeFirstLetter} from "../../util/strings.ts";
import {Entity} from "../../store/model/entity.ts";
import {KnownFactsList} from "../components/known-facts-list.tsx";
import {AssociatedFactsList} from "../components/associated-facts-list.tsx";
import {stringAvatar} from "../components/avatar.ts";
import {nickname} from "../components/group-listitem.tsx";
import {Actions} from "../../store/store.ts";
import {useAppDispatch, useAppSelector} from "../../store/hooks.ts";

export default function EntityLayout({ entity, pretitle, metadata, postknown, preknown }: { entity: Entity; pretitle?: React.ReactNode; metadata?: React.ReactNode; postknown?: React.ReactNode; preknown?: React.ReactNode; }) {
  const dispatch = useAppDispatch()

  const { bookmarked } = useAppSelector((state) => ({
    bookmarked: state.world.state.bookmarkedEntities[entity.name.toLowerCase()] !== undefined,
  }))

  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      <Box sx={{ flex: '40%', padding: theme => theme.spacing(1) }}>
        {pretitle}
        <Box component={"span"} sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 4 }}><Avatar {...stringAvatar(nickname(entity), entity.name, { mr: 2, width: 48, height: 48 })} /><Typography variant={"h2"}>{capitalizeFirstLetter(entity.name)}</Typography></Box>
        <Typography component={"pre"} variant={"body1"} sx={{ mt: 4, mb: 4 }}>{entity.notes}</Typography>
        <FormControlLabel control={<Checkbox checked={bookmarked} onChange={({ target: { checked } }) => checked ? dispatch(Actions.bookmarkEntity({ entityName: entity.name })) : dispatch(Actions.undoBookmarkEntity({ entityName: entity.name }))} />} label={"Bookmark"} />
        {metadata}
      </Box>
      <Box  sx={{ flex: '60%', padding: theme => theme.spacing(1) }}>
        {/*<Divider sx={{ mt: 2, mb: 2 }} />*/}
        {preknown && (
          <>
            {preknown}
            <Divider sx={{ mt: 2, mb: 2 }} />
          </>
        )}
        <KnownFactsList entityNames={[entity.name]} />
        <Divider sx={{ mt: 2, mb: 2 }} />
        {postknown && (
          <>
            {postknown}
            <Divider sx={{ mt: 2, mb: 2 }} />
          </>
        )}
        <AssociatedFactsList entityNames={[entity.name]} />
      </Box>
    </Box>
  )
}