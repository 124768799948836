import {
  Box,
  Chip,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "../../util/strings.ts";
import { Entity } from "../../store/model/entity.ts";
import { Fact } from "../../store/model/fact.ts";
import * as React from "react";
import { useMemo, useState } from "react";
import * as fuzzysort from "fuzzysort";
import { Search } from "@mui/icons-material";

export function FactsList({
  label,
  facts,
  prefillSearch,
}: {
  label: React.ReactNode;
  facts: Array<
    Fact & {
      associatedEntities: Array<Entity & { knower: boolean }>;
    }
  >;
  prefillSearch?: string;
}) {
  const [searchTerm, setSearchTerm] = useState(prefillSearch ?? "");

  const filteredFacts = useMemo(
    () =>
      searchTerm !== ""
        ? fuzzysort
            .go(searchTerm, facts, {
              key: (f) => f.notes + " " + f.associatedEntities.map((e) => e.name).join(" "),
            })
            .map((r) => r.obj)
        : facts,
    [facts, searchTerm],
  );

  return (
    <Box>
      <Box
        sx={{
          // position: "sticky",
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">{label}</Typography>
        <Toolbar sx={{ ml: 3 }}>
          <TextField
            size={"small"}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize={"small"} />
                  </InputAdornment>
                ),
              },
            }}
            label="Search"
          />
        </Toolbar>
      </Box>
      <List>
        {filteredFacts.length > 0 ? (
          filteredFacts.map((fact) => {
            return (
              <ListItem key={fact.key}>
                <ListItemText
                  primary={fact.notes}
                  secondary={
                    <Box
                      sx={{
                        display: "inline-flex",
                        columnGap: (theme) => theme.spacing(1),
                      }}
                    >
                      <Typography variant={"body1"}>{fact.visibilityMod}</Typography>
                      <Box
                        sx={{
                          display: "inline-flex",
                          flexWrap: 'wrap',
                          gap: (theme) => theme.spacing(1),
                        }}
                      >
                        {fact.associatedEntities.map((assocEntity) => (
                          <Chip
                            component={Link}
                            to={`/entity/${assocEntity.name}`}
                            color={
                              assocEntity.knower
                                ? "secondary"
                                : "default"
                            }
                            clickable
                            key={assocEntity.name}
                            label={capitalizeFirstLetter(assocEntity.name)}
                          />
                        ))}
                      </Box>
                    </Box>
                  }
                />
              </ListItem>
            );
          })
        ) : (
          <ListItem>
            <ListItemText primary={"None"} />
          </ListItem>
        )}
      </List>
    </Box>
  );
}
