import {NPC, npcAggregateAttitude} from "../../store/model/npc.ts";
import {Link} from "react-router-dom";
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText
} from "@mui/material";
import {stringAvatar} from "./avatar.ts";
import {capitalizeFirstLetter} from "../../util/strings.ts";
import {GroupAttitudeView} from "./attitude.tsx";
import {useAppSelector} from "../../store/hooks.ts";
import {getCurrentGroupAttitude} from "../../store/model/world.ts";

export default function NPCListItem({ npc }: { npc: NPC }) {
  const { npcAttitude } = useAppSelector((state) => ({
    npcAttitude: npcAggregateAttitude(npc, getCurrentGroupAttitude(state.world, npc.group.toLowerCase())),
  }))
  const { metOnTurn } = useAppSelector((state) => ({
    metOnTurn: state.world.state.metNPCs[npc.name.toLowerCase()],
  }))

  return (
    <ListItem disablePadding>
      <ListItemButton component={Link} to={`/npc/${npc.name}`}>
        <ListItemAvatar>
          <Avatar {...stringAvatar(capitalizeFirstLetter(npc.name))} />
        </ListItemAvatar>
        <ListItemText primary={capitalizeFirstLetter(npc.name)} secondary={<>{npc.attitudeMod > 0 ? `+${npc.attitudeMod}` : npc.attitudeMod < 0 ? `-${npc.attitudeMod}` : npc.attitudeMod}{" → "}<GroupAttitudeView attitude={npcAttitude} sx={{ display: 'inline' }} /><FormControlLabel sx={{ ml: 3 }} control={<Checkbox disabled checked={metOnTurn !== undefined} />} label={`Met${metOnTurn !== undefined ? ` (${metOnTurn})` : ''}`} /></>} />
      </ListItemButton>
    </ListItem>
  )
}