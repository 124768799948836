import {
  configureStore,
  createSelector as tkCreateSelector,
  createSlice,
  PayloadAction,
  SerializedError
} from '@reduxjs/toolkit'
import {getCurrentGroupAttitude, World} from "./model/world.ts";
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE,} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {CreateSelectorFunction, Selector, weakMapMemoize} from "reselect";
import { assert } from "../util/assert.ts";
import {GroupAttitude, groupAttitudeWithMod} from "./model/group.ts";

export type RootStateType = {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  loadError: SerializedError | null;
  world: World;
}

export const initialState = {
  loading: 'idle',
  loadError: null,
  world: {
    data: {
      meta: {
        maxTurns: 25
      },
      groups: {},
      allies: {},
      enemies: {},
      npcs: {},
      places: {},
      things: {},
      facts: {},
      agendaItems: {
        byNpc: {}
      }
    },
    derived: {
      groupNPCs: {},
      knowledge: {
        entitiesToKnownFactsMap: {},
        factsToKnownEntitiesMap: {},
      },
      associations: {
        factsToMentionedEntitiesMap: {},
        entitiesToFactsMentioningMap: {}
      },
      agendaItems: {
        byPlace: {}
      }
    },
    state: {
      currentTurn: 1,
      groupAttitudes: {},
      metNPCs: {},
      bookmarkedEntities: {}
    }
  }
} satisfies RootStateType as RootStateType

// export const loadWorld = createAsyncThunk(
//   'loadWorld',
//   async () => {
//     return await Connection.shared.loadWorld()
//   },
// )

export const rootSlice = createSlice({
  name: 'root',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    loadWorld(state, {payload: {world: newWorld}}: PayloadAction<{ world: World }>) {
      return {
        ...state,
        loading: 'succeeded',
        world: newWorld
      }
    },
    // saveWorld({ world }) {
    //   Connection.shared.saveWorld(world)
    // },
    // deleteWorld() {
    //   Connection.shared.deleteWorld()
    //   return initialState
    // },
    setCurrentTurn ({ world }, { payload: { newTurn } }: PayloadAction<{ newTurn: number }>) {
      // assert(newTurn < world.state.currentTurn, "No time travel! Ain't nothin but a G thang")

      world.state.currentTurn = newTurn;

      // // move npcs
      // for (const npcKey of Object.keys(world.data.npcs) as Array<NPCKey>) {
      //   moveNPCToScheduledLocation(world, npcKey)
      // }
    },
    // start({ world }) {
    //   for (const [groupName, group] of Object.entries(world.data.groups)) {
    //     world.state.groupAttitudes[groupName] = {
    //       attitude1: group.attitude1,
    //       attitude2: group.attitude2,
    //       attitude3: group.attitude3,
    //       attitude4: group.attitude4,
    //       attitude5: group.attitude5
    //     }
    //   }
    //
    //   // world.clock = new WorldClock(world, this.clockOptions)
    //   // world.clock.start()
    // },
    meetNPC({ world }, { payload: { npcName } }: PayloadAction<{ npcName: string; }>) {
      world.state.metNPCs[npcName.toLowerCase()] = world.state.currentTurn
    },
    undoMeetNPCS({ world }, { payload: { npcName } }: PayloadAction<{ npcName: string; }>) {
      delete world.state.metNPCs[npcName.toLowerCase()];
    },
    bookmarkEntity({ world }, { payload: { entityName } }: PayloadAction<{ entityName: string; }>) {
      world.state.bookmarkedEntities[entityName.toLowerCase()] = true
    },
    undoBookmarkEntity({ world }, { payload: { entityName } }: PayloadAction<{ entityName: string; }>) {
      delete world.state.bookmarkedEntities[entityName.toLowerCase()];
    },
    modifyGroupAttitude ({ world }, { payload: { group: groupName, delta, affectAlly = true, affectEnemy = true } }: PayloadAction<{ group: string; delta: number; affectAlly?: boolean; affectEnemy?: boolean; }>) {
      const group = world.data.groups[groupName]
      assert(group !== undefined, "ya crew missin', cuz!")

      const currentGroupAttitude = getCurrentGroupAttitude(world, groupName)

      function setGroupAttitude(g: string, att: GroupAttitude) {
        world.state.groupAttitudes[g]![world.state.currentTurn - 1] = att
        for (let i = world.state.currentTurn; i < world.state.groupAttitudes[g]!.length - 1; i++) {
          delete world.state.groupAttitudes[g]![i]
        }
      }

      const newAttitude = groupAttitudeWithMod(currentGroupAttitude, -delta)
      setGroupAttitude(groupName, newAttitude)

      const allyName = world.data.allies[groupName]
      if (affectAlly && allyName) {
        setGroupAttitude(allyName, groupAttitudeWithMod(getCurrentGroupAttitude(world, allyName), -delta))
      }

      const enemyName = world.data.enemies[groupName]
      if (affectEnemy && enemyName) {
        setGroupAttitude(enemyName, groupAttitudeWithMod(getCurrentGroupAttitude(world, enemyName), delta))
      }
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(loadWorld.pending, (state) => {
  //       state.loading = 'pending'
  //     })
  //     .addCase(loadWorld.fulfilled, (state, action) => {
  //       state.loading = 'succeeded'
  //       state.world = action.payload
  //     })
  //     .addCase(loadWorld.rejected, (state, action) => {
  //       if (
  //         state.loading === 'pending'
  //       ) {
  //         state.loading = 'failed'
  //         state.loadError = action.error
  //       }
  //     })
  // },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState
    });
  }
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootSlice.reducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)

export const Actions = rootSlice.actions;
export const createSelector: CreateSelectorFunction<typeof weakMapMemoize, typeof weakMapMemoize, RootState> = tkCreateSelector;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export type AppSelector<Result = unknown> = Selector<RootState, Result>