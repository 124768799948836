import {Group} from "../../store/model/group.ts";
import {capitalizeFirstLetter} from "../../util/strings.ts";
import {Avatar, ListItem, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";
import {Link} from "react-router-dom";
import {stringAvatar} from "./avatar.ts";
import {GroupAttitudeView} from "./attitude.tsx";
import {useAppSelector} from "../../store/hooks.ts";
import {getCurrentGroupAttitude} from "../../store/model/world.ts";
import {Entity} from "../../store/model/entity.ts";

export function nickname(entity: Entity) {
  return (entity as any).id?.toString() ?? capitalizeFirstLetter(entity.name).split(" ").map((n)=>n[0]).join("")
}

export default function GroupListItem({ group, expanded = true, hideAlly = false, hideEnemy = false }: { group: Group, expanded?: boolean; hideAlly?: boolean; hideEnemy?: boolean }) {
  const { groupAttitude, ally, enemy } = useAppSelector((state) => {
    const allyName = state.world.data.allies[group.name.toLowerCase()];
    const enemyName = state.world.data.enemies[group.name.toLowerCase()];
    return ({
      groupAttitude: getCurrentGroupAttitude(state.world, group.name.toLowerCase()),
      ally: allyName ? state.world.data.groups[allyName.toLowerCase()] : undefined,
      enemy: enemyName ? state.world.data.groups[enemyName.toLowerCase()] : undefined,
    });
  })

  return (
    <ListItem disablePadding sx={{display: 'block'}} secondaryAction={expanded && <>
      {ally && !hideAlly && <Link to={`/group/${ally.name}`} title={`Ally: ${capitalizeFirstLetter(ally.name)}`} style={{ textDecoration: 'none' }}><Avatar {...stringAvatar(nickname(ally), ally.name, { width: 28, height: 28, borderColor: theme => theme.palette.success.main, borderWidth: 2, borderStyle: 'solid' })} /></Link>}
      {enemy && !hideEnemy && <Link to={`/group/${enemy.name}`} title={`Enemy: ${capitalizeFirstLetter(enemy.name)}`} style={{ textDecoration: 'none' }}><Avatar {...stringAvatar(nickname(enemy), enemy.name, { width: 28, height: 28, borderColor: theme => theme.palette.error.main, borderWidth: 2, borderStyle: 'solid' })} /></Link>}
    </>}>
      <ListItemButton
        component={Link}
        to={`/group/${group.name}`}
        sx={[
          {
            minHeight: 48,
            px: 2.5,
          },
          expanded
            ? {
              justifyContent: 'initial',
            }
            : {
              justifyContent: 'center',
            },
        ]}
      >
        <ListItemAvatar
          sx={[
            {
              minWidth: 0,
              justifyContent: 'center',
            },
            expanded
              ? {
                mr: 3,
              }
              : {
                mr: 'auto',
              },
          ]}
        >
          <Avatar {...stringAvatar(nickname(group), group.name)} />
        </ListItemAvatar>
        <ListItemText
          primary={capitalizeFirstLetter(group.name)}
          secondary={<GroupAttitudeView attitude={groupAttitude} />}
          sx={[
            expanded
              ? {
                opacity: 1,
              }
              : {
                opacity: 0,
              },
          ]}
        />
      </ListItemButton>
    </ListItem>
  );
}