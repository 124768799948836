import {useSearchParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {useMemo} from "react";
import {AssociatedFactsList} from "../components/associated-facts-list.tsx";

export default function SearchPage() {
  const [searchParams] = useSearchParams();

  const { entities, filters } = useMemo(() => ({
    entities: searchParams.get("entities")?.split(",")?.filter(v => v !== "") ?? [],
    filters: searchParams.get("filters")?.split(",")?.filter(v => v !== "") ?? []
  }),[searchParams]);

  return (
    <Box key={searchParams.toString()}>
      <Typography variant={"h2"} sx={{ mb: 4 }}>Search</Typography>
      <AssociatedFactsList entityNames={entities} prefillSearch={filters.join(" ")} />
    </Box>
  )
}