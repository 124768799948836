import {useAppSelector} from "../../store/hooks.ts";
import {getEntities} from "../../store/model/world.ts";
import {createSelector, RootState} from "../../store/store.ts";
import {FactsList} from "./fact-list.tsx";
import {FactKey, getAssociatedFacts} from "../../store/model/fact.ts";

const selectFacts = (state: RootState) => state.world.data.facts
const selectAllEntities = (state: RootState) => getEntities(state.world)
const selectAllAssociations = (state: RootState) => state.world.derived.associations
const selectAllKnowledge = (state: RootState) => state.world.derived.knowledge

const selectAssociatedFacts = createSelector([
  (_: RootState, entityNames: string[]) => entityNames,
  selectFacts,
  selectAllEntities,
  selectAllAssociations,
  selectAllKnowledge,
], (entityNames, facts, entities, associations, knowledge) => {
  const factKeys = entityNames.length > 0 ? [...new Set(entityNames.flatMap(entityName => associations.entitiesToFactsMentioningMap[entityName] ?? []))] : Object.keys(facts) as FactKey[]

  return factKeys.map(factKey => {
    const fact = facts[factKey]!
    return ({
      ...fact,
      associatedEntities: getAssociatedFacts(factKey, associations, knowledge, entities)
    });
  }).sort((a, b) => a.index - b.index)
})

export function AssociatedFactsList({entityNames, prefillSearch}: { entityNames: string[]; prefillSearch?: string }) {
  const associatedFacts = useAppSelector(state => selectAssociatedFacts(state, entityNames))

  return (
    <FactsList label={"Associated Facts"} facts={associatedFacts} prefillSearch={prefillSearch} />
  )
}