import { ThemeOptions } from '@mui/material/styles';
import {createTheme} from "@mui/material";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#128A84',
    },
    secondary: {
      main: '#BB5C37',
    },
    success: {
      main: '#79af30',
    },
  },
  typography: {
    fontFamily: '"Victor Mono", "Roboto", "Helvetica", "Arial", sans-serif',
  },
};

export const theme = createTheme(themeOptions)