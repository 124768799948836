import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import Layout from "./layout.tsx";
import GroupPage from "./pages/group.tsx";
import {ClearPersistedRoute} from "./clear-persisted.tsx";
import EntityPage from "./pages/entity.tsx";
import NPCPage from "./pages/npc.tsx";
import PlacePage from "./pages/place.tsx";
import SearchPage from "./pages/search.tsx";
import ThingPage from "./pages/thing.tsx";
import ErrorBoundary from "./components/error-boundary.tsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <ErrorBoundary><Layout /></ErrorBoundary>,
    // ErrorBoundary: ErrorBoundary,
    children: [
      {
        path: '/entity/:entityName',
        element: <EntityPage />,
      },
      {
        path: '/group/:groupName',
        element: <GroupPage />,
      },
      {
        path: '/npc/:npcName',
        element: <NPCPage />,
      },
      {
        path: '/place/:placeName',
        element: <PlacePage />,
      },
      {
        path: '/thing/:thingName',
        element: <ThingPage />,
      },
      {
        path: '/search',
        element: <SearchPage />,
      },
      {
        path: '/*',
        element: <div>Route not found</div>
      }
    ]
  },
  {
    path: '/clear',
    element: <ClearPersistedRoute />
  }
]);

export default function App() {
  return (
    <RouterProvider router={router} />
  )
}
