/* eslint-disable @typescript-eslint/no-explicit-any */
import {z, ZodIssue} from "zod";
import {Sheet2JSONOpts, WorkBook, utils as xlsxUtils} from "xlsx";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {createValidator as _createValidator} from "zod-xlsx"

export type ValidResource<T extends z.ZodType> = {
  isValid: true;
  data: z.infer<T>;
};

export type InvalidResource = {
  issues: ZodIssue[];
  isValid: false;
  data: Record<string, unknown>;
};

export type Result<T extends z.ZodType> = {
  valid: ValidResource<T>[];
  invalid: InvalidResource[];
};

export interface ValidatorOptions {
  header?: Sheet2JSONOpts["header"];
  sheetName?: string;
  blankrows?: Sheet2JSONOpts["blankrows"];
  skipHidden?: Sheet2JSONOpts["skipHidden"];
  onValid?: (data: any) => void;
  onInvalid?: (data: any) => void;
}

export function createValidator(workbook: WorkBook, opts?: ValidatorOptions): {
  validate: <T extends z.ZodType<any, z.ZodTypeDef, any>>(schema: T) => Result<T>;
  rows: unknown[];
  header: unknown;
  validateAsync: <T extends z.ZodType<any, z.ZodTypeDef, any>>(schema: T, options?: {
    batchSize: number;
  }) => Promise<Result<T>>;
} {
  return _createValidator(workbook, opts);
}

export const utils = xlsxUtils