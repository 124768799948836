import {z} from "zod";
import {World} from "./world.ts";
import {xlsxError} from "./error.ts";
import XLSX from "xlsx";
import {createValidator} from "./xlsx.ts";

const PlaceSchema = z.object({
  name: z.string(),
  notes: z.string().optional()
});

// extract the inferred type
export type PlaceData = z.infer<typeof PlaceSchema>;
export type Place = PlaceData & {
  _type: 'place'
}

export async function loadPlacesFromWorkbook(world: World, workbook: XLSX.WorkBook) {
  const validator = createValidator(workbook, {
    sheetName: 'Places'
  })

  const result = validator.validate(PlaceSchema);

  if (result.invalid.length > 0) {
    throw xlsxError('Places', result.invalid)
  }

  return loadPlaces(world, result.valid.map(r => r.data))
}

export function loadPlaces(world: World, datas: PlaceData[]) {
  for (const data of datas) {
    const name = data.name.toLowerCase();
    world.data.places[name] = {
      _type: 'place',
      ...data,
      name
    }
  }
}