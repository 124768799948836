import {useEffect} from "react";
import {persistor} from "../store/store.ts";
import {useNavigate} from "react-router-dom";

export function ClearPersistedRoute() {
  const navigate = useNavigate();

  useEffect(() => {
    persistor.purge().then(() => {
      navigate("/")
    })
  }, []);

  return null;
}