import {Link, useParams} from "react-router-dom";
import {useAppSelector} from "../../store/hooks.ts";
import EntityLayout from "./_layout.tsx";
import {PlaceAgendaList} from "../components/place-agenda-list.tsx";
import {getLocationCurrentNPCS, getLocationCurrentThings} from "../../store/model/world.ts";
import NPCListItem from "../components/npc-listitem.tsx";
import {Box, List, ListItem, ListItemButton, ListItemText, Typography} from "@mui/material";
import {capitalizeFirstLetter} from "../../util/strings.ts";

export default function PlacePage() {
  const { placeName } = useParams()
  const { place } = useAppSelector((state) => ({
    place: state.world.data.places[placeName!.toLowerCase()]!,
  }))
  const { currentNPCs } = useAppSelector((state) => ({
    currentNPCs: getLocationCurrentNPCS(state.world, place.name.toLowerCase()).map(npcName => state.world.data.npcs[npcName]!),
  }))
  const { currentThings } = useAppSelector((state) => ({
    currentThings: getLocationCurrentThings(state.world, place.name.toLowerCase()).map(thingName => state.world.data.things[thingName]!),
  }))

  return (
    <EntityLayout entity={place} metadata={<>
      <Typography variant="h6" sx={{ mt: 4 }}>Current NPCs</Typography>
      <List>
        {currentNPCs.length > 0 ? currentNPCs.map(npc => (
          <NPCListItem key={npc.name} npc={npc} />
        )) : <ListItem><ListItemText primary={"None"} /></ListItem>}
      </List>
      <Typography variant="h6" sx={{ mt: 4 }}>Things</Typography>
      <List>
        {currentThings.length > 0 ? currentThings.map(thing => (
          <ListItem disablePadding key={thing.name}>
            <ListItemButton component={Link} to={`/thing/${thing.name}`}>
              <ListItemText primary={capitalizeFirstLetter(thing.name)} />
            </ListItemButton>
          </ListItem>
        )) : <ListItem><ListItemText primary={"None"} /></ListItem>}
      </List>
      <Box sx={{ mt: 4 }} />
      <PlaceAgendaList placeNames={[placeName!.toLowerCase()]} />
    </>} />
  )
}