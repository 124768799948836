import {useAppSelector} from "../../store/hooks.ts";
import {createSelector, RootState} from "../../store/store.ts";
import {AgendaList} from "./agenda-list.tsx";

const selectAllAgendaItemsByNPCs = (state: RootState) => state.world.data.agendaItems.byNpc
const selectAllAgendaItemsByPlace = (state: RootState) => state.world.derived.agendaItems.byPlace
const selectAllPlaces = (state: RootState) => state.world.data.places
const selectAllNPCs =  (state: RootState) => state.world.data.npcs

const selectAgendaItems = createSelector([
  (_: RootState, npcNames: string[]) => npcNames,
  selectAllAgendaItemsByNPCs,
  selectAllAgendaItemsByPlace,
  selectAllPlaces,
  selectAllNPCs,
], (npcNames, allAgendaItemsByNPC, allAgendaItemsByPlace, allPlaces, allNPCs) => {
  return npcNames.flatMap(npcName => (allAgendaItemsByNPC[npcName.toLowerCase()] ?? []).map((placeName, turn) => {
    if (placeName) {
      return {
        turn: turn + 1,
        location: allPlaces[placeName.toLowerCase()]!,
        npcs: (allAgendaItemsByPlace[placeName]![turn] ?? []).map(npcName => allNPCs[npcName.toLowerCase()]!)
      }
    } else {
      return undefined
    }
  }).filter(e => e !== undefined))
})

export function NPCAgendaList({npcNames, prefillSearch}: { npcNames: string[]; prefillSearch?: string }) {
  const agendaItems = useAppSelector(state => selectAgendaItems(state, npcNames))

  return (
    <AgendaList label={"Agenda"} agendaItems={agendaItems} prefillSearch={prefillSearch} />
  )
}