import {useAppSelector} from "../../store/hooks.ts";
import {getEntities} from "../../store/model/world.ts";
import {createSelector, RootState} from "../../store/store.ts";
import {FactsList} from "./fact-list.tsx";
import {getAssociatedFacts} from "../../store/model/fact.ts";
import React from "react";

const selectFacts = (state: RootState) => state.world.data.facts
const selectAllEntities = (state: RootState) => getEntities(state.world)
const selectAllAssociations = (state: RootState) => state.world.derived.associations
const selectAllKnowledge = (state: RootState) => state.world.derived.knowledge

const selectKnownFacts = createSelector([
  (_: RootState, entityNames: string[]) => entityNames,
  selectFacts,
  selectAllEntities,
  selectAllAssociations,
  selectAllKnowledge,
], (entityNames, facts, entities, associations, knowledge) => {
  const factKeys = [...new Set(entityNames.flatMap(entityName => knowledge.entitiesToKnownFactsMap[entityName] ?? []))]
  return factKeys.map(factKey => {
    const fact = facts[factKey]!
    return ({
      ...fact,
      associatedEntities: getAssociatedFacts(factKey, associations, knowledge, entities)
    });
  }).sort((a, b) => a.index - b.index)
})

export function KnownFactsList({label, entityNames, prefillSearch}: { highlightKnown?: boolean; label?: React.ReactNode; entityNames: string[]; prefillSearch?: string }) {
  const knownFacts = useAppSelector(state => selectKnownFacts(state, entityNames))

  return (
    <FactsList label={label ?? "Known Facts"} facts={knownFacts} prefillSearch={prefillSearch} />
  )
}