import {createSelector, RootState} from "../../store/store.ts";
import {getEntities} from "../../store/model/world.ts";
import {useAppSelector} from "../../store/hooks.ts";
import {Navigate, useParams} from "react-router-dom";

const selectAllEntities = (state: RootState) => getEntities(state.world)

const selectEntity = createSelector([
  (_: RootState, entityName: string) => entityName,
  selectAllEntities,
], (entityName, entities) => {
  return entities[entityName]!;
})

export default function EntityPage() {
  const { entityName } = useParams()

  const entity = useAppSelector(state => selectEntity(state, entityName!));

  switch (entity._type) {
    case "group":
      return <Navigate to={`/group/${entityName}`} replace />
    case "npc":
      return <Navigate to={`/npc/${entityName}`} replace />
    case "place":
      return <Navigate to={`/place/${entityName}`} replace />
    case "thing":
      return <Navigate to={`/thing/${entityName}`} replace />
    default:
      return <Navigate to={"*"} replace />
  }
}