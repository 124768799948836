import {useMemo, useState} from "react";
import fuzzysort from "fuzzysort";
import {
  Box,
  Button,
  Chip,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
  Toolbar,
  Typography
} from "@mui/material";
import {Search} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {capitalizeFirstLetter} from "../../util/strings.ts";
import * as React from "react";
import {NPC} from "../../store/model/npc.ts";
import {Place} from "../../store/model/place.ts";

export function AgendaList({label,
  agendaItems,
  prefillSearch,
}: {
  label: React.ReactNode;
  agendaItems: Array<{
    turn: number;
    npcs: NPC[];
    location: Place;
  }>;
  prefillSearch?: string;
}) {
  const [searchTerm, setSearchTerm] = useState(prefillSearch ?? "");

  const sortedAgendaItems = useMemo(() => [...agendaItems].sort((a, b) => a.turn - b.turn), [agendaItems])

  const filteredAgendaItems = useMemo(
    () =>
      searchTerm !== ""
        ? fuzzysort
          .go(searchTerm, sortedAgendaItems, {
            key: (a) => a.turn + " " + a.location.name + " " + a.npcs.map(npc => npc.name).join(" "),
          })
          .map((r) => r.obj)
        : sortedAgendaItems,
    [sortedAgendaItems, searchTerm],
  );

  return (
    <Box>
      <Box
        sx={{
          // position: "sticky",
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">{label}</Typography>
        <Toolbar sx={{ ml: 3 }}>
          <TextField
            size={"small"}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize={"small"} />
                  </InputAdornment>
                ),
              },
            }}
            label="Search"
          />
        </Toolbar>
      </Box>
      <List>
        {filteredAgendaItems.length > 0 ? (
          filteredAgendaItems.map((agendaItem) => {
            return (
              <ListItem key={`${agendaItem.location.name} ${agendaItem.turn}`}>
                <ListItemText
                  primary={`Turn ${agendaItem.turn}`}
                  secondary={
                    <Box
                      sx={{
                        display: "inline-flex",
                        columnGap: (theme) => theme.spacing(1),
                      }}
                    >
                      <Button component={Link} variant={"text"} to={`/place/${agendaItem.location.name}`}>{capitalizeFirstLetter(agendaItem.location.name)}</Button>
                      <Box
                        sx={{
                          display: "inline-flex",
                          flexWrap: 'wrap',
                          gap: (theme) => theme.spacing(1),
                        }}
                      >
                        {agendaItem.npcs.map((npc) => (
                          <Chip
                            component={Link}
                            to={`/npc/${npc.name}`}
                            clickable
                            key={npc.name}
                            label={capitalizeFirstLetter(npc.name)}
                          />
                        ))}
                      </Box>
                    </Box>
                  }
                />
              </ListItem>
            );
          })
        ) : (
          <ListItem>
            <ListItemText primary={"None"} />
          </ListItem>
        )}
      </List>
    </Box>
  );
}
