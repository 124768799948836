import React, {useState} from "react";
import {Box, Button, Checkbox, FormControlLabel, Popover, TextField} from "@mui/material";
import {useAppDispatch} from "../../store/hooks.ts";
import {Actions} from "../../store/store.ts";

export function ModifyGroupAttitudeForm({ groupName }: { groupName: string }) {
  const dispatch = useAppDispatch()

  const [deltaInput, setDeltaInput] = useState<string>("");
  const [affectAlly, setAffectAlly] = useState(true);
  const [affectEnemy, setAffectEnemy] = useState(true);

  return (
    <Box component={"form"} sx={{ display: 'flex', flexDirection: 'column', padding: '10px' }} onSubmit={e => {
      e.preventDefault()

      dispatch(Actions.modifyGroupAttitude({ group: groupName, delta: Number(deltaInput), affectAlly, affectEnemy }))
    }}>
      <TextField type={"number"} label={"Mod Att. by:"} value={deltaInput} onChange={({ target: { value } }) => setDeltaInput(value)} />
      <FormControlLabel control={<Checkbox checked={affectAlly} onChange={(_, checked) => setAffectAlly(checked)} />} label="Affect Ally?" />
      <FormControlLabel control={<Checkbox checked={affectEnemy} onChange={(_, checked) => setAffectEnemy(checked)} />} label="Affect Enemy?" />
      <Button type={"submit"}>Submit</Button>
    </Box>
  )
}

export function ModifyGroupAttitudeButton({ groupName }: { groupName: string }) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'mod-group-att-popover' : undefined;

  return (
    <>
      <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Modify Group Attitude
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ModifyGroupAttitudeForm groupName={groupName} />
      </Popover>
    </>
  )
}