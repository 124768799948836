import {AssociationGraph} from "./assoc.ts";
import {KnowledgeGraph} from "./knowledge.ts";
import {Group, GroupAttitude, loadGroupsFromWorkbook} from "./group.ts";
import {loadAlliesFromWorkbook} from "./ally.ts";
import {loadEnemiesFromWorkbook} from "./enemy.ts";
import {loadNPCsFromWorkbook, NPC} from "./npc.ts";
import {loadPlacesFromWorkbook, Place} from "./place.ts";
import {loadThingsFromWorkbook, Thing} from "./thing.ts";
import {Fact, FactKey, loadFactsFromWorkbook} from "./fact.ts";
import XLSX from "xlsx";
import {loadAgendaItemsFromWorkbook} from "./agenda.ts";

export type WorldData = {
  meta: {
    maxTurns: number
  }
  groups: {
    [name: string]: Group
  }
  allies: {
    [groupName: string]: string
  }
  enemies: {
    [groupName: string]: string
  }
  npcs: {
    [name: string]: NPC
  };
  places: {
    [name: string]: Place
  };
  things: {
    [name: string]: Thing
  };
  facts: {
    [key: FactKey]: Fact
  };
  agendaItems: {
    byNpc: {
      // to place
      [npcName: string]: Array<string | undefined>
    }
  }
}

export async function loadWorldDataXLSX(world: World, workbookFile: File) {
  const buf = await workbookFile.arrayBuffer();
  const workbook = XLSX.read(buf);

  await loadPlacesFromWorkbook(world, workbook)
  await loadGroupsFromWorkbook(world, workbook)
  await loadAlliesFromWorkbook(world, workbook)
  await loadEnemiesFromWorkbook(world, workbook)
  await loadNPCsFromWorkbook(world, workbook)
  await loadThingsFromWorkbook(world, workbook)
  await loadFactsFromWorkbook(world, workbook)
  await loadAgendaItemsFromWorkbook(world, workbook)
}

export type WorldDerived = {
  groupNPCs: { [group: string]: string[] };
  knowledge: KnowledgeGraph;
  associations: AssociationGraph;
  agendaItems: {
    byPlace: {
      // to npc
      [placeName: string]: Array<string[] | undefined>
    }
  }
}

export type WorldState = {
  currentTurn: number;
  groupAttitudes: {
    // by turn
    [groupName: string]: Array<GroupAttitude>
  }
  metNPCs: {
    // turn when met
    [npcName: string]: number
  }
  bookmarkedEntities: {
    [entityName: string]: true | undefined
  }
}

// class World (entities, groups, npcs, places, things, facts, knowledge, time, agenda items, associations)
export interface World {
  data: WorldData
  derived: WorldDerived
  state: WorldState
}

export function getEntities(world: World): { [name: string]: Group | NPC | Place | Thing } {
  return {
    ...world.data.groups,
    ...world.data.npcs,
    ...world.data.places,
    ...world.data.things
  }
}

export function getCurrentGroupAttitude(world: World, groupName: string): GroupAttitude {
  const currentTurn = world.state.currentTurn

  for (let i = currentTurn - 1; i >= 0; i--) {
    const attitude = world.state.groupAttitudes[groupName]?.[i]
    if (attitude) {
      return attitude
    }
  }

  return world.data.groups[groupName]!
}

export function getNPCCurrentLocation(world: World, npc: string) {
  const currentTurn = world.state.currentTurn

  for (let i = currentTurn - 1; i >= 0; i--) {
    const location = world.data.agendaItems.byNpc[npc]?.[i]
    if (location) {
      return location
    }
  }

  return world.data.npcs[npc]!.location
}

export function getAllNPCsCurrentLocations(world: World) {
  return Object.keys(world.data.npcs).reduce((acc, npc) => {
    return {
      ...acc,
      [npc]: getNPCCurrentLocation(world, npc)
    }
  }, {} as { [npc: string]: string })
}

export function getLocationCurrentNPCS(world: World, location: string) {
  const allNPCsCurrentLocations = getAllNPCsCurrentLocations(world)

  return Object.keys(allNPCsCurrentLocations).filter(npc => allNPCsCurrentLocations[npc] === location)
}

export function getThingCurrentLocation(world: World, thing: string) {
  return world.data.things[thing]!.location
}

export function getAllThingsCurrentLocations(world: World) {
  return Object.keys(world.data.things).reduce((acc, thing) => {
    return {
      ...acc,
      [thing]: getThingCurrentLocation(world, thing)
    }
  }, {} as { [thing: string]: string | undefined })
}

export function getLocationCurrentThings(world: World, location: string) {
  const allThingsCurrentLocations = getAllThingsCurrentLocations(world)

  return Object.keys(allThingsCurrentLocations).filter(thing => allThingsCurrentLocations[thing] === location)
}