import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {styled, Theme} from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import {useAppDispatch, useAppSelector} from "../store/hooks.ts";
import {cloneDeep} from "lodash-es";
import {Actions, initialState, persistor, RootState} from "../store/store.ts";
import {getEntities, loadWorldDataXLSX} from "../store/model/world.ts";
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Autocomplete,
  Avatar, Badge,
  Box,
  Button,
  Chip,
  CSSObject,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText, Menu, MenuItem,
  MobileStepper,
  TextField,
  Toolbar as MuiToolbar,
  Typography,
  useTheme
} from "@mui/material";
import {
  BookmarksOutlined,
  ChevronLeft,
  ChevronRight,
  Clear,
  House,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  People
} from "@mui/icons-material";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import {stringAvatar} from "./components/avatar.ts";
import {capitalizeFirstLetter} from "../util/strings.ts";
import GroupListItem from "./components/group-listitem.tsx";
import {useErrorBoundary} from "react-error-boundary";
import packageJson from "../../package.json"

const drawerWidth = 300;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme}) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({open}) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
  ({theme}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({open}) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({open}) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

const Toolbar = styled(MuiToolbar)(({theme}) => ({
  columnGap: theme.spacing(2),
}))

// const SearchContainer = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(1),
//     width: 'auto',
//   },
// }));
//
// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));
//
// const SearchInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   width: '100%',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     [theme.breakpoints.up('sm')]: {
//       width: '12ch',
//       '&:focus': {
//         width: '20ch',
//       },
//     },
//   },
// }));

const selectAllEntities = (state: RootState) => getEntities(state.world)

function Search() {
  const navigate = useNavigate()
  const location = useLocation()
  const loading = useAppSelector((state) => state.loading)

  const entities = useAppSelector(selectAllEntities);
  const entityNames = useMemo(() => Object.keys(entities), [entities]);
  // console.log(entityNames);

  const [values, setValues] = useState<string[]>([])
  const {selectedEntityNames, wildcardFilters} = useMemo(() => {
    const selectedEntityNames: string[] = []
    const wildcardFilters: string[] = []

    for (const value of values) {
      if (entityNames.includes(value)) {
        selectedEntityNames.push(value)
      } else {
        wildcardFilters.push(value)
      }
    }

    return {
      selectedEntityNames,
      wildcardFilters
    }
  }, [values, entityNames])

  useEffect(() => {
    if (!location.pathname.startsWith("/search")) {
      setValues([])
    }
  }, [location.pathname]);

  if (loading !== 'succeeded') {
    return (<Box sx={{flexGrow: 1}}/>)
  }

  return (
    <Box component={"form"} onSubmit={(e) => {
      e.preventDefault()
      if (selectedEntityNames.length === 1 && wildcardFilters.length === 0) {
        navigate(`/entity/${selectedEntityNames[0]}`)
      } else {
        const urlParams = new URLSearchParams({
          entities: selectedEntityNames.join(","),
          filters: wildcardFilters.join(","),
        })
        navigate(`/search?${urlParams.toString()}`)
      }
    }} sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
      <Autocomplete
        freeSolo
        value={values}
        onChange={(_, value) => setValues(value)}
        options={entityNames}
        multiple
        getOptionLabel={(option) => capitalizeFirstLetter(option)}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => {
            const {key, ...tagProps} = getTagProps({index});
            return (
              <Chip label={option} key={key} {...tagProps} />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            name={"layout-search-input"}
            variant="filled"
            placeholder={"Search..."}
          />
        )}
        sx={{flexGrow: 1}}
      />
      <Button variant={"text"} color={"inherit"} type={"submit"}>{
        selectedEntityNames.length === 1 && wildcardFilters.length === 0 ? "Go" : "Search"
      }</Button>
      {/*{values.length !== 0 ? (*/}
      {/*  values.length === 1 ? <Navigate to={`/entity/${values[0]}`} replace /> : <Navigate to={`/search/${values.join(",")}`} replace />*/}
      {/*) : null}*/}
    </Box>
  )
}

function TurnNav() {
  const loading = useAppSelector((state) => state.loading)
  const dispatch = useAppDispatch()

  const maxTurns = useAppSelector(state => state.world.data.meta.maxTurns)
  const currentTurn = useAppSelector(state => state.world.state.currentTurn)

  const handleNext = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    dispatch(Actions.setCurrentTurn({newTurn: currentTurn + 1}))
  };

  const handleBack = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
    dispatch(Actions.setCurrentTurn({newTurn: currentTurn - 1}))
  };

  if (loading !== 'succeeded') {
    return (<Box/>)
  }

  return (
    <Box>
      <MobileStepper
        variant="text"
        steps={maxTurns}
        position="static"
        activeStep={currentTurn - 1}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={currentTurn === maxTurns}
          >
            Next
            <KeyboardArrowRight/>
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={currentTurn === 1}>
            <KeyboardArrowLeft/>
            Back
          </Button>
        }
        sx={{
          borderRadius: 2
        }}
      />
    </Box>
  )
}

export default function Layout() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{display: 'flex'}}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                marginRight: 5,
              },
              open && {display: 'none'},
            ]}
          >
            <MenuIcon/>
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{display: {xs: 'none', sm: 'block'}}}>
            Mystery Machine (v{packageJson.version})
          </Typography>
          <Search/>
          <WorldSelector/>
          <TurnNav/>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRight/> : <ChevronLeft/>}
          </IconButton>
        </DrawerHeader>
        <Divider/>
        <DrawerContent open={open}/>
      </Drawer>
      <Box component="main" sx={{flexGrow: 1, p: 3}}>
        <DrawerHeader/>
        <LoadingGate>
          <Outlet/>
        </LoadingGate>
      </Box>
    </Box>
  );
}

function DrawerContent({open}: { open: boolean }) {
  const loading = useAppSelector((state) => state.loading)

  if (loading !== "succeeded") {
    return null
  }

  return (
    <LoadedDrawerContent open={open}/>
  )
}

function LoadedDrawerContent({open}: { open: boolean }) {
  const {groups, places} = useAppSelector((state) => ({
    groups: state.world.data.groups,
    places: state.world.data.places,
  }))

  const {bookmarkedEntities} = useAppSelector((state) => ({
    bookmarkedEntities: Object.keys(state.world.state.bookmarkedEntities),
  }))

  return (
    <>
      <List dense>
        {bookmarkedEntities.length > 0 &&
          <BookmarkMenu drawerOpen={open}/>}
        <ListItem disablePadding sx={{justifyContent: 'center'}}>
          <ListItemButton sx={[
            {
              minHeight: 48,
              px: 2.5,
            },
            {
              justifyContent: 'center'
            }
          ]}>
            <ListItemIcon
              sx={[
                {
                  minWidth: 0,
                  justifyContent: 'center',
                },
                open
                  ? {
                    mr: 3,
                  }
                  : {
                    mr: 'auto',
                  },
              ]}
            >
              <House/>
            </ListItemIcon>
            <ListItemText
              primary={"Places"}
              sx={[
                open
                  ? {
                    opacity: 1,
                  }
                  : {
                    opacity: 0,
                  },
              ]}
            />
          </ListItemButton>
        </ListItem>
        {Object.keys(places).map((placeName) => {
          const place = places[placeName]!;
          const nickname = capitalizeFirstLetter(place.name).split(" ").map((n) => n[0]).join("")

          return (
            <ListItem key={placeName} disablePadding sx={{display: 'block'}}>
              <ListItemButton
                component={Link}
                to={`/place/${place.name}`}
                sx={[
                  {
                    minHeight: 48,
                    px: 2.5,
                  },
                  open
                    ? {
                      justifyContent: 'initial',
                    }
                    : {
                      justifyContent: 'center',
                    },
                ]}
              >
                <ListItemAvatar
                  sx={[
                    {
                      minWidth: 0,
                      justifyContent: 'center',
                    },
                    open
                      ? {
                        mr: 3,
                      }
                      : {
                        mr: 'auto',
                      },
                  ]}
                >
                  <Avatar {...stringAvatar(nickname, place.name)} />
                </ListItemAvatar>
                <ListItemText
                  primary={capitalizeFirstLetter(place.name)}
                  sx={[
                    open
                      ? {
                        opacity: 1,
                      }
                      : {
                        opacity: 0,
                      },
                  ]}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <Divider/>
      <List dense>
        <ListItem disablePadding sx={{justifyContent: 'center'}}>
          <ListItemButton sx={[
            {
              minHeight: 48,
              px: 2.5,
            },
            {
              justifyContent: 'center'
            }
          ]}>
            <ListItemIcon
              sx={[
                {
                  minWidth: 0,
                  justifyContent: 'center',
                },
                open
                  ? {
                    mr: 3,
                  }
                  : {
                    mr: 'auto',
                  },
              ]}
            >
              <People/>
            </ListItemIcon>
            <ListItemText
              primary={"Groups"}
              sx={[
                open
                  ? {
                    opacity: 1,
                  }
                  : {
                    opacity: 0,
                  },
              ]}
            />
          </ListItemButton>
        </ListItem>
        {Object.keys(groups).map((groupName) => {
          const group = groups[groupName]!;

          return <GroupListItem expanded={open} key={group.name} group={group}/>
        })}
      </List>
    </>
  )
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function WorldSelector() {
  const loading = useAppSelector((state) => state.loading)
  const dispatch = useAppDispatch()
  const {showBoundary} = useErrorBoundary();

  return (
    <Box component={"span"} sx={{display: 'inline-flex', columnGap: theme => theme.spacing(2)}}>
      <Button
        component="label"
        role={undefined}
        color={"inherit"}
        variant="outlined"
        tabIndex={-1}
        sx={{flexShrink: 0}}
        // startIcon={<CloudUpload />}
      >
        {loading !== "succeeded" ? "Select World File" : "World Loaded"}
        <VisuallyHiddenInput
          type="file"
          onChange={async e => {
            const file = e.target.files?.[0];
            if (file) {
              const w = cloneDeep(initialState.world)
              try {
                await loadWorldDataXLSX(w, file)

                dispatch(Actions.loadWorld({world: w}))
              } catch (e) {
                showBoundary(e)
              }
            }
          }}
        />
      </Button>
      {loading === "succeeded" && <IconButton color={"inherit"} onClick={() => {
        persistor.purge()
      }}>
        <Clear/>
      </IconButton>}
    </Box>
  );
}

function BookmarkMenu({drawerOpen}: { drawerOpen: boolean }) {
  const {bookmarkedEntities} = useAppSelector((state) => ({
    bookmarkedEntities: Object.keys(state.world.state.bookmarkedEntities),
  }))

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ListItem disablePadding sx={{justifyContent: 'center'}}>
        <ListItemButton onClick={handleMenu} sx={[
          {
            minHeight: 48,
            px: 2.5,
          },
          {
            justifyContent: 'center'
          }
        ]}>
          <ListItemIcon
            sx={[
              {
                minWidth: 0,
                justifyContent: 'center',
              },
              drawerOpen
                ? {
                  mr: 3,
                }
                : {
                  mr: 'auto',
                },
            ]}
          >
            <Badge badgeContent={bookmarkedEntities.length} color="primary">
              <BookmarksOutlined/>
            </Badge>
          </ListItemIcon>
          <ListItemText
            primary={"Bookmarks"}
            sx={[
              drawerOpen
                ? {
                  opacity: 1,
                }
                : {
                  opacity: 0,
                },
            ]}
          />
        </ListItemButton>
      </ListItem>
      <Menu
        anchorEl={anchorEl}
        // anchorOrigin={{
        //   vertical: 'top',
        //   horizontal: 'right',
        // }}
        // keepMounted
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'right',
        // }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {bookmarkedEntities.map((entityName) => (
          <MenuItem component={Link} to={`/entity/${entityName}`} key={entityName}
                    onClick={handleClose}>{capitalizeFirstLetter(entityName)}</MenuItem>
        ))}
      </Menu>
    </>
  )
}

function LoadingGate({children}: { children: React.ReactNode }) {
  const {loading, loadError} = useAppSelector((state) => ({loading: state.loading, loadError: state.loadError}))

  if (loading === 'idle') {
    return null
  }

  if (loading === 'pending') {
    return "Loading World..."
  }

  if (loading === 'failed') {
    return `Error: ${loadError?.message}`
  }

  return <>{children}</>
}