import {Link, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks.ts";
import {Box, Button, Checkbox, Divider, FormControlLabel, Typography} from "@mui/material";
import {GroupAttitudeView} from "../components/attitude.tsx";
import {npcAggregateAttitude} from "../../store/model/npc.ts";
import EntityLayout from "./_layout.tsx";
import {getCurrentGroupAttitude, getNPCCurrentLocation} from "../../store/model/world.ts";
import GroupListItem from "../components/group-listitem.tsx";
import {NPCAgendaList} from "../components/npc-agenda-list.tsx";
import {capitalizeFirstLetter} from "../../util/strings.ts";
import {ModifyGroupAttitudeButton} from "../components/modify-group-attitude-form.tsx";
import {KnownFactsList} from "../components/known-facts-list.tsx";
import {Actions} from "../../store/store.ts";

export default function NPCPage() {
  const { npcName } = useParams()

  const dispatch = useAppDispatch()
  const { npc } = useAppSelector((state) => ({
    npc: state.world.data.npcs[npcName!.toLowerCase()]!,
  }))
  const { group, npcAttitude } = useAppSelector((state) => ({
    group: state.world.data.groups[npc.group.toLowerCase()]!,
    npcAttitude: npcAggregateAttitude(npc, getCurrentGroupAttitude(state.world, npc.group.toLowerCase())),
  }))
  const { currentLocation } = useAppSelector((state) => ({
    currentLocation: getNPCCurrentLocation(state.world, npc.name.toLowerCase()),
  }))
  const { metOnTurn } = useAppSelector((state) => ({
    metOnTurn: state.world.state.metNPCs[npc.name.toLowerCase()],
  }))

  return (
    <EntityLayout
      entity={npc}
      pretitle={<Box>
        <GroupListItem group={group}/>
        <Divider />
      </Box>}
      preknown={<>
        <KnownFactsList label={"Group Known"} entityNames={[npc.group]} highlightKnown />
      </>}
      metadata={<>
        <FormControlLabel control={<Checkbox checked={metOnTurn !== undefined} onChange={({ target: { checked } }) => checked ? dispatch(Actions.meetNPC({ npcName: npc.name })) : dispatch(Actions.undoMeetNPCS({ npcName: npc.name }))} />} label={`Met by Players${metOnTurn !== undefined ? ` on turn ${metOnTurn}` : ''}`} />
        <Typography variant={'h6'}>{npc.attitudeMod > 0 ? `+${npc.attitudeMod}` : npc.attitudeMod < 0 ? `-${npc.attitudeMod}` : npc.attitudeMod}</Typography>
        <GroupAttitudeView variant={"h6"} attitude={npcAttitude}/>
        <ModifyGroupAttitudeButton groupName={npc.group.toLowerCase()} />
        <Typography variant="h6" sx={{ mt: 4 }}>Current Location</Typography>
        <Button component={Link} variant={"text"} to={`/place/${currentLocation}`}>{capitalizeFirstLetter(currentLocation)}</Button>
        <Box sx={{ mt: 4 }} />
        <NPCAgendaList npcNames={[npcName!.toLowerCase()]} />
      </>}
    />
  )
}