import {useAppSelector} from "../../store/hooks.ts";
import {createSelector, RootState} from "../../store/store.ts";
import {AgendaList} from "./agenda-list.tsx";

const selectAllAgendaItemsByPlace = (state: RootState) => state.world.derived.agendaItems.byPlace
const selectAllPlaces = (state: RootState) => state.world.data.places
const selectAllNPCs =  (state: RootState) => state.world.data.npcs

const selectAgendaItems = createSelector([
  (_: RootState, placeNames: string[]) => placeNames,
  selectAllAgendaItemsByPlace,
  selectAllPlaces,
  selectAllNPCs,
], (placeNames, allAgendaItemsByPlace, allPlaces, allNPCs) => {
  return placeNames.flatMap(placeName => (allAgendaItemsByPlace[placeName.toLowerCase()] ?? []).map((placeEntry, turn) => {
    if (placeEntry) {
      return {
        turn: turn + 1,
        location: allPlaces[placeName.toLowerCase()]!,
        npcs: placeEntry.map(npcName => allNPCs[npcName.toLowerCase()]!)
      }
    } else {
      return undefined
    }
  }).filter(e => e !== undefined))
})

export function PlaceAgendaList({placeNames, prefillSearch}: { placeNames: string[]; prefillSearch?: string }) {
  const agendaItems = useAppSelector(state => selectAgendaItems(state, placeNames))

  return (
    <AgendaList label={"Agenda"} agendaItems={agendaItems} prefillSearch={prefillSearch} />
  )
}