import {useErrorBoundary, ErrorBoundary as ReactErrorBoundary, FallbackProps} from "react-error-boundary";
import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {persistor} from "../../store/store.ts";

function ErrorFallback({ error }: FallbackProps) {
  const { resetBoundary } = useErrorBoundary();

  const clearWorld = () => {
    persistor.purge()
    resetBoundary()
  }

  return (
    <Box role="alert" sx={{padding: '30px' }}>
      <Typography variant={"body1"}>Something went wrong:</Typography>
      <Typography component={"pre"} variant={"body1"} style={{ color: "red" }}>{error.message}</Typography>
      {error.stack && (
        <Typography component={"pre"} variant={"body1"} style={{ color: "red" }}>{error.stack}</Typography>
      )}
      <Button onClick={resetBoundary}>Dismiss</Button>
      <Button color={"error"} onClick={clearWorld}>Clear World</Button>
    </Box>
  );
}

export default function ErrorBoundary({ children }: { children?: React.ReactNode }) {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
    >
      {children}
    </ReactErrorBoundary>
  )
}