import {getEntities, World} from "./world.ts";
import {assert} from "../../util/assert.ts";
import {v4 as uuidv4} from "uuid";
import {z} from "zod";
import XLSX from "xlsx";
import {createValidator} from "./xlsx.ts";
import {xlsxError} from "./error.ts";
import {NPC} from "./npc.ts";
import {AssociationGraph} from "./assoc.ts";
import {Entity} from "./entity.ts";
import {KnowledgeGraph} from "./knowledge.ts";

const FactSchema = z.object({
  notes: z.string(),
  entity: z.string().optional(),
  visibilityMod: z.number().optional(),
});

// extract the inferred type
export type FactData = z.infer<typeof FactSchema>;

export type FactKey = string & { brand: `fact-key`; };

export type Fact = FactData & {
  key: FactKey
  index: number;
}

export async function loadFactsFromWorkbook(world: World, workbook: XLSX.WorkBook) {
  const validator = createValidator(workbook, {
    sheetName: 'Facts'
  })

  const result = validator.validate(FactSchema);

  if (result.invalid.length > 0) {
    throw xlsxError('Facts', result.invalid)
  }

  return loadFacts(world, result.valid.map(r => r.data))
}

export function loadFacts(world: World, factsData: FactData[]) {
  const allEntities = getEntities(world)

  for (const [row, data] of factsData.entries()) {
    const key = uuidv4() as FactKey
    const fact: Fact = {
      key,
      ...data,
      index: row,
      entity: data.entity?.toLowerCase()
    }

    world.data.facts[key] = fact

    const addEntityAssoc = (entityName: string) => {
      // add to factsToMentionedEntitiesMap
      world.derived.associations.factsToMentionedEntitiesMap[fact.key] = [...new Set([...world.derived.associations.factsToMentionedEntitiesMap[fact.key] ?? [], entityName])]

      // add to entitiesToFactsMentioningMap
      world.derived.associations.entitiesToFactsMentioningMap[entityName] = [...new Set([...world.derived.associations.entitiesToFactsMentioningMap[entityName] ?? [], fact.key])]
    }

    // parse out associations
    for (const [entityName, entity] of Object.entries(allEntities)) {
      if (fact.notes.toLowerCase().includes(entityName)) {
        addEntityAssoc(entityName)

        if ((entity as NPC)._type === "npc") {
          const npc = entity as NPC

          addEntityAssoc(npc.group)
        }
      }
    }

    if (fact.entity) {
      const entity = allEntities[fact.entity]
      assert(entity !== undefined, `facts error (line ${row}): someone done gone stole yo dime bag: entity ${fact.entity} in fact\n:${JSON.stringify(fact, null, 2)}\n\nLoaded entities: \n${Object.keys(allEntities).join(",\n")}`)

      // add to factsToKnownEntitiesMap
      world.derived.knowledge.factsToKnownEntitiesMap[fact.key] ??= []
      world.derived.knowledge.factsToKnownEntitiesMap[fact.key]!.push(fact.entity)

      // add to entitiesToKnownFactsMap
      world.derived.knowledge.entitiesToKnownFactsMap[fact.entity] ??= []
      world.derived.knowledge.entitiesToKnownFactsMap[fact.entity]!.push(fact.key)
    }
  }
}

export function getAssociatedFacts(factKey: FactKey, associations: AssociationGraph, knowledge: KnowledgeGraph, entities: {
  [key: string]: Entity
}) {
  return (associations.factsToMentionedEntitiesMap[factKey] ?? []).map(mentionedEntityName => ({
    ...entities[mentionedEntityName]!,
    knower: (knowledge.entitiesToKnownFactsMap[mentionedEntityName] ?? []).includes(factKey),
  }))
}