import {z} from "zod";
import {World} from "./world.ts";
import {Group} from "./group.ts";
import XLSX from "xlsx";
import {createValidator} from "./xlsx.ts";
import {xlsxError} from "./error.ts";
import {assert} from "../../util/assert.ts";

const EnemySchema = z.object({
  group: z.string(),
  enemy: z.string()
});

export type EnemyData = z.infer<typeof EnemySchema>;

export function getEnemyOfGroup(world: World, groupName: string): Group | undefined {
  const enemyEntry = world.data.enemies[groupName]
  return enemyEntry ? world.data.groups[enemyEntry] : undefined
}

export async function loadEnemiesFromWorkbook(world: World, workbook: XLSX.WorkBook) {
  const validator = createValidator(workbook, {
    sheetName: 'Enemies'
  })

  const result = validator.validate(EnemySchema);

  if (result.invalid.length > 0) {
    throw xlsxError('Enemies', result.invalid)
  }

  return loadEnemies(world, result.valid.map(r => r.data))
}

export function loadEnemies(world: World, datas: EnemyData[]) {
  for (const [row, data] of datas.entries()) {
    const name = data.group.toLowerCase();
    const group = world.data.groups[name]
    assert(group !== undefined, `enemy error (line ${row}): someone done gone stole yo dime bag: group ${name} does not exist`)

    const enemyName = data.enemy.toLowerCase()
    const enemy = world.data.groups[enemyName]
    assert(enemy !== undefined, `enemy error (line ${row}): someone done gone stole yo dime bag: group ${enemyName} does not exist`)

    world.data.enemies[name] = data.enemy.toLowerCase()
    // reversed lookup too
    world.data.enemies[data.enemy.toLowerCase()] = name
  }
}