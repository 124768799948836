import {Link, useParams} from "react-router-dom";
import {useAppSelector} from "../../store/hooks.ts";
import EntityLayout from "./_layout.tsx";
import {Button, Typography} from "@mui/material";
import {capitalizeFirstLetter} from "../../util/strings.ts";
import {getThingCurrentLocation} from "../../store/model/world.ts";

export default function ThingPage() {
  const { thingName } = useParams()
  const { thing } = useAppSelector((state) => ({
    thing: state.world.data.things[thingName!.toLowerCase()]!,
  }))
  const { currentLocation } = useAppSelector((state) => ({
    currentLocation: getThingCurrentLocation(state.world, thing.name.toLowerCase()),
  }))

  return (
    <EntityLayout entity={thing} metadata={<>
      <Typography variant={"caption"}>Visibility Mod</Typography>
      <Typography variant={"body1"}>{thing.visibilityMod ?? "--"}</Typography>
      {currentLocation && <>
        <Typography variant="h6" sx={{ mt: 4 }}>Location</Typography>
        <Button component={Link} variant={"text"} to={`/place/${currentLocation}`}>{capitalizeFirstLetter(currentLocation)}</Button>
      </>}
    </>} />
  )
}